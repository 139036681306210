<template>
  <stats-card header-color="green">
    <template slot="header">
      <div class="card-icon">
        <md-icon>thermostat</md-icon>
      </div>
      <p class="category">Luftdaten</p>
      <h3 class="title">
        {{ luftdaten.name }}
      </h3>
      <h4>
        <span v-html="getStyledPM10Text(luftdaten.pm10)" />
        <br />

        <span v-html="getStyledPM25Text(luftdaten.pm25)" />
        <br />
        Temperature <b>{{ luftdaten.temp }}</b> ℃<br />
        Humidity <b>{{ luftdaten.humid }}</b
        >%
      </h4>
    </template>

    <template slot="footer">
      <div class="stats">
        <md-icon>timelapse</md-icon>
        measured {{ luftdaten.elapsed }} ago <br />
        <small>
          <a :href="luftdaten.meteo" target="_blank"
            ><span class="material-icons">thermostat</span></a
          >
          <a :href="luftdaten.feinstaub" target="_blank"
            ><span class="material-icons">foggy</span></a
          >
          <a :href="luftdaten.rexfue" target="_blank"
            ><span class="material-icons">auto_graph</span></a
          >
          <a :href="luftdaten.opensensemap" target="_blank"
            ><span class="material-icons">grain</span></a
          ></small
        >
      </div>
    </template>
  </stats-card>
</template>
<script>
import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard,
  },
  name: "meteo-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },

    luftdaten: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    getStyledPM10Text(pm10) {
      let color = "green";
      if (pm10 > 50) {
        color = "red";
      } else if (pm10 > 10) {
        color = "orange";
      }
      return `PM10 <small>[µg/m³] </small><b style="color: ${color}">${pm10}</b>`;
    },
    getStyledPM25Text(pm25) {
      let color = "green";
      if (pm25 > 20) {
        color = "red";
      } else if (pm25 > 10) {
        color = "orange";
      }
      return `PM2.5 <small>[µg/m³] </small><b style="color: ${color}">${pm25}</b>`;
    },
  },
};
</script>
