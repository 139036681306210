<template>
  <div>
    <md-table :table-header-color="tableHeaderColor">
      <md-table-row v-for="item in lastClimateUsersData" :key="item.ID">
        <md-table-cell md-label="Channel" class="flag"
          ><img :src="item.flag" style="max-width: 32px" />
        </md-table-cell>
        <md-table-cell md-label="Count"
          ><b>{{ item.country }}</b
          >, {{ item.city }}<br /><small>{{ item.isp }}</small></md-table-cell
        >
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "last-climate-users-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    lastClimateUsersData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selected: [],
    };
  },
};
</script>
